import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/Guards/AuthGuard';
import GuestGuard from 'src/components/Guards/GuestGuard';
import HomeView from 'src/views/home/HomeView';
import UserGuard from 'src/components/Guards/UserGuard';
import SettingsLayout from 'src/views/Test1';
import FeeSettingsLayout from 'src/views/FeeTest';
import SettingsGuard from 'src/components/Guards/SetingsGuard';
import DashboardLayoutSettings from 'src/layouts/DashboardLayoutSettings';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/accessdenied',
    component: lazy(() => import('src/views/errors/AccessDenied'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView/JWTLogin'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,

    path: '/reset_password',
    component: lazy(() => import('src/views/auth/LoginView/Reset_Password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot',
    component: lazy(() => import('src/views/auth/LoginView/Forgot_Password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/confirm',
    component: lazy(() =>
      import('src/views/auth/LoginView/Confirm_forgot_password')
    )
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/app/inquiry/:type/:schedule_id',
    component: lazy(() => import('src/views/AddEmployeeInquiryDetails'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/app/admission/inquiry/:type/:schedule_id',
    component: lazy(() => import('src/views/AddEmployeeInquiryAdmissionDetails'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/app/inquiry/submit',
    component: lazy(() => import('src/views/AddEmployeeInquiryDetails/SubmitInquiry'))
  },
  {
    path: '/app/admin/settings',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
     
      {
        exact: true,
        path: '/app/admin/settings/GeneralSettings/Personal',
        layout:SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/PersonalSetup'))
      },
      {
        exact: true,
        path: '/app/admin/settings/GeneralSettings/Company',
        layout:SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstitutionNew'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Integration',
        layout:SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/Integrations/Integration'))
      },
      {
        exact: true,
        path: '/app/admin/settings/email/Email',
        layout:SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/EmailTemplate'))
      },
    
    
      {
        exact: true,
        path: '/app/admin/settings/programs',
       layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/Setup/Programs'))
      },
      {
        exact: true,
        guard: SettingsGuard,       layout : SettingsLayout,

        path: '/app/admin/settings/onboarding/students/:admission_status',
        component: lazy(() => import('src/views/Onboard/Students'))
      },
      {
        exact: true,
        guard: SettingsGuard,
        layout : SettingsLayout,
        path: '/app/admin/settings/onboarding/active/students/:type/quick_admission',
        component: lazy(() =>
          import('src/views/Onboard/AddStudentQuickAdmission')
        )
      },
     
      {
        exact: true,
        guard: SettingsGuard,
        path: '/app/admin/settings/students/bulk_admission',
        component: lazy(() =>
          import('src/views/Onboard/Students/BulkUploadStudents')
        )
      },
      {
        exact: true,
        guard: SettingsGuard,        layout : SettingsLayout,

        path: '/app/admin/settings/onboarding/previous_students/:admission_status',
        component: lazy(() => import('src/views/Onboard/PreviousStudents'))
      },
      {
        exact: true,
        guard: SettingsGuard,layout : SettingsLayout,
        path: '/app/admin/settings/onboarding/previous/students/:type/quick_admission',
        component: lazy(() =>
          import('src/views/Onboard/AddStudentQuickAdmission')
        )
      },
      {
        exact: true,
        guard: SettingsGuard,
        path: '/app/admin/settings/previous_students/bulk_admission',
        component: lazy(() =>
          import('src/views/Onboard/PreviousStudents/BulkUploadStudents')
        )
      },
      {
        exact: true,
        guard: SettingsGuard,
        layout : SettingsLayout,

        path: '/app/admin/settings/user/user_management',
        component: lazy(() => import('src/views/UserManagement'))
      },

      {
        exact: true,
        guard: SettingsGuard,                layout : SettingsLayout,


        path: '/app/admin/settings/admission/promote_students',
        component: lazy(() => import('src/views/PromoteStudents'))
      },
      {
        exact: true,
        guard: SettingsGuard,                layout : SettingsLayout,


        path: '/app/admin/settings/admission/enabledsoon/:page_type',
        component: lazy(() => import('src/views/StayTuned'))
      },
      {
        exact: true,
        guard: SettingsGuard,                layout : SettingsLayout,


        path: '/app/admin/settings/data/enabledsoon/:page_type',
        component: lazy(() => import('src/views/StayTuned'))
      },
      {
        exact: true,
        guard: SettingsGuard,                layout : SettingsLayout,


        path: '/app/admin/settings/user/enabledsoon/:page_type',
        component: lazy(() => import('src/views/StayTuned'))
      },
      {
        exact: true,
        guard: SettingsGuard,                layout : SettingsLayout,


        path: '/app/admin/settings/Compliances/enabledsoon/:page_type',
        component: lazy(() => import('src/views/StayTuned'))
      },
      {
        exact: true,
        guard: SettingsGuard,                layout : SettingsLayout,


        path: '/app/admin/settings/email/enabledsoon/:page_type',
        component: lazy(() => import('src/views/StayTuned'))
      },
      {
        guard: SettingsGuard,
        layout : SettingsLayout,
        exact: true,
        path: '/app/admin/settings/general',
        component: lazy(() => import('src/views/Setup/Details'))
      },
      {
        guard: SettingsGuard,
        layout : SettingsLayout,

        exact: true,
        path: '/app/admin/settings/admission/forms',
        component: lazy(() => import('src/views/Setup/StudentForms'))
      },
      {
        guard: SettingsGuard,
        exact: true,        layout : SettingsLayout,

        path: '/app/admin/settings/admission/templates',
        component: lazy(() => import('src/views/Setup/Templates'))
      },
      {
        guard: SettingsGuard,
        exact: true,
        layout : SettingsLayout,
        path: '/app/admin/settings/admissionschedule',
        component: lazy(() => import('src/views/Setup/AdmissionSchedule'))
      },
      
      {
        guard: SettingsGuard,
        exact: true,
        layout : SettingsLayout,

        path: '/app/admin/settings/addadmissionschedule',
        component: lazy(() =>
          import('src/views/Setup/AdmissionSchedule/Ongoing')
        )
      },
      {
        guard: SettingsGuard,
        exact: true,
        path: '/app/admin/settings/schedule/:id/edit',
        component: lazy(() =>
          import('src/views/Setup/AdmissionSchedule/EditSchedule')
        )
      },
      {
        exact: true,
        path: '/app/admin/settings/quota',
       layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/Setup/Quota'))
      },
      // {
      //   exact: true,
      //   path: '/app/admin/settings/org/stream',
      // layout : SettingsLayout,
      //   guard: SettingsGuard,
      //   component: lazy(() => import('src/views/Setup/Stream'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/settings/stream/addStream',
      //  layout : SettingsLayout,
      //   guard: SettingsGuard,
      //   component: lazy(() => import('src/views/InstSetup/Stream/AddStreamNew'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/settings/stream/:id',
      //  layout : SettingsLayout,
      //   guard: SettingsGuard,
      //   component: lazy(() => import('src/views/InstSetup/Stream/AddStream'))
      // },
      {
        exact: true,
        path: '/app/admin/settings/Academic/stream/:id',
     layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Stream/AddStreamNew'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Academic/stream/:id/edit',
        layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Stream/StreamEditView'))
      },
    //   {
    //     exact: true,
    //     path: '/app/admin/settings/org/batch',
    //     guard: SettingsGuard,
    //  layout : SettingsLayout,
    //     component: lazy(() => import('src/views/Setup/Batch'))
    //   },
      {
        exact: true,
        path: '/app/admin/settings/Academic/batch/addBatch',
     layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Batch/AddBatch'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Academic/batch/:id',
      layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Batch/BatchDetailView'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Academic/batch/:id/edit',
        layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Batch/BatchEditView'))
      },
      // {
      //   exact: true,
      //   path: '/app/admin/settings/org/academicyear',
      //  layout : SettingsLayout,
      //   guard: SettingsGuard,
      //   component: lazy(() => import('src/views/Setup/AcademicYear'))
      // },

      
    
      {
        exact: true,
        path: '/app/admin/settings/quota',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/Setup/Quota'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Academic/stream',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Stream'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Academic/stream/addStream',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Stream/AddStream'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Academic/stream/:id',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Stream/Details'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Academic/stream/:id/edit',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Stream/StreamEditView'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Academic/batch',
        layout: SettingsLayout,
        component: lazy(() => import('src/views/InstSetup/Batch'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Academic/batch/addBatch',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Batch/AddBatch'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Academic/batch/:id',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Batch/BatchDetailView'))
      },
      {
        exact: true,
        path: '/app/admin/settings/Academic/batch/:id/edit',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/Batch/BatchEditView'))
      },

      {
        exact: true,
        path: '/app/admin/settings/Academic/academicyear',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/InstSetup/AcademicYear'))
      },

      {
        exact: true,
        path: '/app/admin/settings',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: () => <Redirect to="/app/admin/settings/programs" />
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        layout : SettingsLayout,
        exact: true,
        path: '/app/fee/settings/fee_settings/feeschedule/addfeeschedule',
        component: lazy(() => import('src/views/Setup/FeeSchedule/Ongoing'))
      },
      {
        exact: true,
        path: '/app/fee/settings/fee_settings/fee_structure/add',
       layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() =>
          import('src/views/FeeSetup/FeeStructure/AddFeeStructure')
        )
      },
      {
        exact: true,
        path: '/app/fee/settings/fee_settings/fee_structure/:id',
       layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() =>
          import('src/views/FeeSetup/FeeStructure/EditFeeStructure')
        )
      },
      {
        exact: true,
        path: '/app/fee/settings/fee_settings/fee_structure/:id/detail',
         layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() =>
          import('src/views/FeeSetup/FeeStructure/FeeStructureDetailView')
        )
      },
      {
        exact: true,
        layout : SettingsLayout,

        path: '/app/fee/settings/fee_settings/feeschedule',
        component: lazy(() => import('src/views/Setup/FeeSchedule'))
      },
      {
        exact: true,
        path: '/app/fee/settings/fee_settings',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/FeeSetup/FeeType'))
      },
      {
        exact: true,
        path: '/app/fee/settings/fee_settings/accounts',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/FeeSetup/Accounts'))
      },
      {
        exact: true,
        path: '/app/fee/settings/fee_settings/fee_categories',
        layout: SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/FeeSetup/FeeCategory'))
      },
      // {
      //   exact: true,
      //   path: '/app/admin/settings/admission/Certificates',
      //   layout: SettingsLayout,
      //   guard: SettingsGuard,
      //   component: lazy(() => import('src/views/StayTuned'))
      // },
      // {
      //   layout : SettingsLayout,
      //   exact: true,
      //   guard: SettingsGuard,

      //   path: '/app/admin/settings/admission/enabledsoon/:page_type',
      //   component: lazy(() => import('src/views/EnableSoon'))
      // },
      {
        exact: true,
        path: '/app/fee/settings/fee_settings/fee_structure',
        layout:SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/FeeSetup/FeeStructure'))
      },
      {
        exact: true,
        path: '/app/fee/schedule',

        component: lazy(() => import('src/views/Schedule'))
      },
      {
        exact: true,
        path: '/app/new_admission/:page_id',
        component: lazy(() => import('src/views/Admissions/Schedules'))
      },
      {
        exact: true,
        path: '/app/new_admission/:page_id/:schedule_id',
        component: lazy(() => import('src/views/Admissions/Schedules/AddSchedules'))
      },
      {
        exact: true,
        path: '/app/add_admission/:page_id',
        component: lazy(() => import('src/views/Admissions/Schedules/AddSchedules'))
      },
      {
        exact: true,
        path: '/app/admission_sched_detail/:page_id/:schedule_id',
        component: lazy(() => import('src/views/Admissions/Schedules'))
      },
      {
        exact: true,
        path: '/app/admin/add/:type/:schedule_id',
        component: lazy(() => import('src/views/AddEmployeeDetails'))
      },
      {
        exact: true,
        path: '/app/admin/:type/edit/:id/:layout_id',
        component: lazy(() => import('src/views/AddEmployeeDetails'))
      },
      {
        exact: true,
        path: '/app/admin/students/admission/:page_type/:admission_status',
        component: lazy(() => import('src/views/StudentsList'))
      },
      {
        exact: true,
        path: '/app/admin/students/fee/:page_type/:admission_status',
        component: lazy(() => import('src/views/StudentsList'))
      },
      {
        exact: true,
        path: '/app/admin/students/admission/:page_type/:admission_status/:id/:layout_id',
        component: lazy(() => import('src/views/StudentFee'))
      },
      {
        exact: true,
        path: '/app/admin/students/tracker/admission/:page_type/:admission_status/:id/:layout_id',
        component: lazy(() => import('src/views/StudentFee'))
      },
      {
        exact: true,
        path: '/app/admin/:type/:id/:layout_id',
        component: lazy(() => import('src/views/Library/StudentFee'))
      },
      {
        exact: true,
        path: '/app/admin/student/Update/:type/:id/:layout_id',
        component: lazy(() => import('src/views/Library/AddEmployeeDetails'))
      },
      {
        exact: true,
        path: '/app/admin/students/fee/:page_type/:admission_status/:id/:layout_id',
        component: lazy(() => import('src/views/StudentFee'))
      },
      {
        exact: true,
        path: '/app/admission/certificates',
        component: lazy(() => import('src/views/Certificates'))
      },

      {
        exact: true,
        path: '/app/fee/allStudents',
        component: lazy(() => import('src/views/AllStudents'))
      },
      {
        exact: true,
        path: '/app/admin/students/add_section',
        component: lazy(() =>
          import('src/views/AdmissionList/BulkUploadSection')
        )
      },
      {
        exact: true,
        path: '/app/hostel/allStudents',
        component: lazy(() => import('src/views/AllStudentsHostel'))
      },
      {
        exact: true,
        path: '/app/fee/schedule/:id/bulk_upload_transactions',
        component: lazy(() =>
          import('src/views/Schedule/BulkUploadTransactions')
        )
      },
      {
        exact: true,
        path: '/app/fee/schedule/:id/bulk_upload_orders/:category',
        component: lazy(() =>
          import('src/views/Schedule/BulkUploadOrders')
        )
      },
      {
        exact: true,
        path: '/app/fee/transactions/students/create_order',
        component: lazy(() => import('src/views/InvoiceNew'))
      },
      {
        exact: true,
        path: '/app/fee/transactions/parties/create_order',
        component: lazy(() => import('src/views/InvoiceNew'))
      },
      {
        exact: true,
        path: '/app/fee/schedule/:id/:status',
        component: lazy(() => import('src/views/Schedule/DraftScheduleFee'))
      },
      {
        exact: true,
        path: '/app/fee/scholarship/:id',
        component: lazy(() =>
          import('src/views/ScollarshipSchedule/ActiveScholarshipSchedule')
        )
      },
      // {
      //   exact: true,
      //   path: '/app/scholarship/schedule/:id/:status',
      //   component: lazy(() => import('src/views/Scholarship/DraftScheduleFee'))
      // },
      // {
      //   exact: true,
      //   // guard: SettingsGuard,
      //   path: '/app/fee/dashboard',
      //   component: lazy(() => import('src/views/Dashboard/Dash'))
      // },
      {
        exact: true,
        // guard: SettingsGuard,
        path: '/app/fee/dashboard',
        component: lazy(() => import('src/views/DashboardNew'))
      },
      {
        exact: true,
        // guard: SettingsGuard,
        path: '/app/fee/dashboard/FeePe',
        component: lazy(() => import('src/views/FeePe'))
      },
      {
        exact: true,
        // guard: SettingsGuard,
        path: '/app/fee/home/exam',
        component: lazy(() => import('src/views/ExamDashboard'))
      },
      {
        exact: true,
        path: '/app/fee/collectfee',
        component: lazy(() => import('src/views/CollectFee'))
      },
      {
        exact: true,
        path: '/app/fee/collectfee/feeId/:id',
        component: lazy(() => import('src/views/CollectFee/StudentsByFeeId'))
      },
      {
        exact: true,
        path: '/app/fee/collectfee/feeId/student/:id',
        component: lazy(() => import('src/views/CollectFee/StudentsFee'))
      },
      {
        exact: true,
        path: '/app/fee/collectfee/Order/:id',
        component: lazy(() => import('src/views/CollectFee/CollectFee'))
      },
      {
        exact: true,
        path: '/app/fee/transactions',
        component: lazy(() => import('src/views/TransactionsNew'))
      },
      {
        exact: true,
        path: '/app/hostel/transactions',
        component: lazy(() => import('src/views/HostelTransactions'))
      },
      {
        exact: true,
        path: '/app/accounts/transactions',
        component: lazy(() => import('src/views/AccountsTransactions'))
      },
      {
        exact: true,
        path: '/app/CRM/:type/:page_id',
        component: lazy(() => import('src/views/Library/Employee'))
      },
      {
        exact: true,
        path: '/app/LibraryCRM/:type/:page_id/:app_name/:module_name',
        component: lazy(() => import('src/views/Library/Employee'))
      },
      {
        exact: true,
        path: '/app/HostelCRM/:type/:page_id/:app_name/:module_name',
        component: lazy(() => import('src/views/Library/Employee'))
      },
      {
        exact: true,
        path: '/app/Circulation/:type/:page_id/:app_name/:module_name',
        component: lazy(() => import('src/views/Library/Employee'))
      },
      {
        exact: true,
        path: '/app/Orders/:type/:page_id/:app_name/:module_name',
        component: lazy(() => import('src/views/Library/Employee'))
      },
      {
        exact: true,
        path: '/app/Transactions/:type/:page_id/:app_name/:module_name',
        component: lazy(() => import('src/views/Library/Employee'))
      },
      {
        exact: true,
        path: '/app/Certificates/:type/:page_id/:app_name/:module_name',
        component: lazy(() => import('src/views/Library/Employee'))
      },
      {
        exact: true,
        path: '/app/Asset/:type/:page_id/:app_name/:module_name',
        component: lazy(() => import('src/views/Library/Employee'))
      },
      {
        exact: true,
        path: '/app/BillingCRM/:type/:page_id',
        component: lazy(() => import('src/views/Library/Employee'))
      },
      {
        exact: true,
        path: '/app/crm/:type/:page_id',
        component: lazy(() => import('src/views/Library/Employee'))
      },
      {
        exact: true,
        path: '/app/admin/book/:page_id',
        component: lazy(() => import('src/views/Library/Employee'))
      },
      {
        exact: true,
        path: '/app/CRM/check/:type/:page_id/:app_name/:module_name',
        component: lazy(() => import('src/views/Library/Employee'))
      },
      {
        exact: true,
        path: '/app/cem/check/:type/:page_id',
        component: lazy(() => import('src/views/Library/Employee'))
      },
      {
        exact: true,
        path: '/app/admin/Id_card/:page_id',
        component: lazy(() => import('src/views/Library/Employee'))
      },
      {
        exact: true,
        path: '/app/admin/Add/CRM/:type/:page_id/:module_name/:app_name',
        component: lazy(() => import('src/views/Library/AddEmployeeDetails'))
      },
      {
        exact: true,
        path: '/app/admin/Add/Asset/:type/:page_id/:module_name/:app_name',
        component: lazy(() => import('src/views/Library/AddAsset'))
      },
      {
        exact: true,
        path: '/app/reports/CRM/Library',
        component: lazy(() => import('src/views/Library/Reports'))
      },
      {
        exact: true,
        path: '/app/lib_reports/CRM/Library/timesheet_report',
        component: lazy(() => import('src/views/Library/Reports/TimesheetReport'))
      },
      
      //  {
      //   exact: true,
      //   path: '/app/admin/students/admission/:admission_status/:id/:layout_id',
      //   component: lazy(() => import('src/views/Library/StudentFee'))
      // },
      {
        exact: true,
        path: '/app/CRM/device/:type/:page_id/:app_name/:module_name',
        component: lazy(() => import('src/views/Library/Employee'))
      },
      {
        exact: true,
        path: '/app/crm/device/:type/:page_id',
        component: lazy(() => import('src/views/Library/Employee'))
      },
      {
        exact: true,
        path: '/app/billing/transactions',
        component: lazy(() => import('src/views/BillingTransactions'))
      },
      {
        exact: true,
        path: '/app/billing/Invoice',
        component: lazy(() => import('src/views/Invoice'))
      },
      {
        exact: true,
        path: '/app/billing/Invoice/InvoiceNew',
        component: lazy(() => import('src/views/InvoiceNew'))
      },
      {
        exact: true,
        path: '/app/billing/Invoice/InvoiceView',
        component: lazy(() => import('src/views/InvoiceView'))
      },
      {
        exact: true,
        path: '/app/billing/Invoice/InvoiceEdit',
        component: lazy(() => import('src/views/InvoiceEdit'))
      },
      {
        exact: true,
        path: '/app/fee/transaction/:id',
        component: lazy(() => import('src/views/Transactions/PaymentDetail'))
      },
      {
        exact: true,
        path: '/app/fee/refund/:id',
        component: lazy(() => import('src/views/Transactions/RefundDetail'))
      },
      {
        exact: true,
        path: '/app/fee/order/:id',
        component: lazy(() => import('src/views/Transactions/OrderDetail'))
      },
      {
        exact: true,
        path: '/app/fee/order/exam/:id',
        component: lazy(() => import('src/views/Transactions/OrderDetailExam'))
      },
      {
        exact: true,
        path: '/app/fee/order/exam/:id/collectfee',
        component: lazy(() =>
          import('src/views/Transactions/OrderDetailExamCollectFee')
        )
      },
      {
        exact: true,
        path: '/app/fee/remainders',
        component: lazy(() => import('src/views/Remainders'))
      },
      {
        exact: true,
        path: '/app/fee/reports',
        component: lazy(() => import('src/views/Reports'))
      },
      {
        exact: true,
        path: '/app/admission/reports',
        component: lazy(() => import('src/views/AdmissionReports'))
      },
      {
        exact: true,
        path: '/app/admission/approvals',
        component: lazy(() => import('src/views/Approvals'))
      },
      {
        exact: true,
        path: '/app/fee/approvals',
        component: lazy(() => import('src/views/Approvals'))
      },
      {
        exact: true,
        path: '/app/admin/reports/DayBookCustom1',
        component: lazy(() =>
          import('src/views/AdmissionReports/DayBookReportCustom1')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/AdmissionRegister',
        component: lazy(() =>
          import('src/views/AdmissionReports/AdmissionRegister')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/AbstractReport',
        component: lazy(() =>
          import('src/views/AdmissionReports/AbstractReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/CET',
        component: lazy(() => import('src/views/AdmissionReports/CETReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/DTE',
        component: lazy(() => import('src/views/AdmissionReports/DTEReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/NewDTE',
        component: lazy(() => import('src/views/AdmissionReports/NewDTEReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/AdmissionDetailed',
        component: lazy(() =>
          import('src/views/AdmissionReports/AdmissionDetailedReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/VTUAll',
        component: lazy(() => import('src/views/AdmissionReports/VTUAllReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/VTU',
        component: lazy(() => import('src/views/AdmissionReports/VTUReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/AdmissionCount',
        component: lazy(() =>
          import('src/views/AdmissionReports/AdmissionCountReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/ScheduleCount',
        component: lazy(() =>
          import('src/views/AdmissionReports/ScheduleCountReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/studentAddressMbaCustom',
        component: lazy(() =>
          import('src/views/AdmissionReports/StudentStickerReport')
        )
      },
      // {
      //   exact: true,
      //   path: '/app/admin/reports/DayBook',
      //   component: lazy(() => import('src/views/AdmissionReports/DayBookReport'))
      // },
      {
        exact: true,
        path: '/app/admin/reports/StudentList',
        component: lazy(() =>
          import('src/views/AdmissionReports/StudentListReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/Cancellation',
        component: lazy(() =>
          import('src/views/AdmissionReports/CancellationReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/GenderCount',
        component: lazy(() =>
          import('src/views/AdmissionReports/GenderCountReport')
        )
      },
      // {
      //   exact: true,
      //   path: '/app/admin/help_section',
      //   guard: SettingsGuard,
      //   component: lazy(() => import('src/views/HelpSection'))
      // },
      {
        exact: true,
        path: '/app/admission/allStudents',
        component: lazy(() => import('src/views/AdmissionList'))
      },
      {
        exact: true,
        path: '/app/admission/schedule',
        component: lazy(() => import('src/views/AllAdmissions/Admissions'))
      },
      {
        exact: true,
        path: '/app/admission/schedule/:id',
        component: lazy(() => import('src/views/AllAdmissions'))
      },
      {
        exact: true,
        path: '/app/admission/allStudents/:id',
        component: lazy(() =>
          import('src/views/AdmissionList/AdmitDetailViewforadmitted')
        )
      },
      {
        exact: true,
        path: '/app/admission/admitStudent/:scheduleId/:id',
        component: lazy(() => import('src/views/AllAdmissions/AdmitStudent'))
      },
      {
        exact: true,
        path: '/app/admission/verifyStudent/:scheduleId/:id',
        component: lazy(() => import('src/views/AllAdmissions/AdmitDetailView'))
      },
      {
        exact: true,
        path: '/app/accounts',
        component: lazy(() => import('src/views/Accounts/RTGS_NEFT'))
      },
      {
        exact: true,
        path: '/app/admin/scholarship',
        component: lazy(() => import('src/views/Scollarship'))
      },
      {
        exact: true,
        path: '/app/admin/add_scholarship',
        component: lazy(() => import('src/views/AddScollarship'))
      },
      {
        exact: true,
        path: '/app/admin/scholarship_schedule',
        component: lazy(() => import('src/views/ScollarshipSchedule'))
      },
      {
        exact: true,
        path: '/app/fee/student_detail/:student_id/miscellaneous',
        component: lazy(() =>
          import('src/views/AllStudents/StudentFee/MiscellanousFee')
        )
      },
      // {
      //   exact: true,
      //   path: '/app/fee/student_detail/:student_id/:fee_category',
      //   component: lazy(() =>
      //     import('src/views/AllStudents/StudentFee/AdmissionFee')
      //   )
      // },
      {
        exact: true,
        path: '/app/fee/student_detail/:student_id/:layout_id/create_orders',
        component: lazy(() =>
          import('src/views/InvoiceNew')
        )
      },
      {
        exact: true,
        path: '/app/fee/student_detail/:student_id/:layout_id/add/fee_structure',
        component: lazy(() =>
          import('src/views/AddFeeStructure')
        )
      },
      {
        exact: true,
        path: '/app/fee/student_detail/:student_id/:layout_id/:_id/edit/fee_structure',
        component: lazy(() =>
          import('src/views/AddFeeStructure')
        )
      },
      {
        exact: true,
        path: '/app/admin/miscellaneousfee',
        component: lazy(() => import('src/views/Miscellaneous/'))
      },
      {
        exact: true,
        path: '/app/fee/student_detail/:student_id',
        component: lazy(() => import('src/views/AllStudents/StudentFee'))
      },
      {
        exact: true,
        path: '/app/hostel/student_detail/:student_id',
        component: lazy(() => import('src/views/AllStudentsHostel/StudentFee'))
      },
      {
        exact: true,
        path: '/app/admin/student_scholarship_detail/:student_id',
        component: lazy(() =>
          import('src/views/Scollarship/StudentScholarshipView')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/admission/certificate_register',
        component: lazy(() => import('src/views/Reports/CertificateRegister'))
      },
      {
        exact: true,
        path: '/app/admin/reports/fee/certificate_register',
        component: lazy(() => import('src/views/Reports/CertificateRegister'))
      },
      {
        exact: true,
        path: '/app/admin/reports/DayBook',
        component: lazy(() => import('src/views/Reports/DayBookReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/DayBookNew',
        component: lazy(() => import('src/views/Reports/DayBookReportNew'))
      },
      {
        exact: true,
        path: '/app/admin/reports/MbaCustomDayBook',
        component: lazy(() => import('src/views/Reports/CustomDayBookReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/MonthlyAbstract',
        component: lazy(() => import('src/views/Reports/MonthlyAbstractReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/GeneralMonthlyAbstract',
        component: lazy(() =>
          import('src/views/Reports/GeneralMonthlyAbstractReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/YearlyAbstract',
        component: lazy(() => import('src/views/Reports/YearlyAbstractReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/GeneralYearlyAbstract',
        component: lazy(() =>
          import('src/views/Reports/GeneralYearlyAbstractReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/DemandCollection',
        component: lazy(() =>
          import('src/views/Reports/DemandCollectionReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/HeadWiseDemandCollection',
        component: lazy(() =>
          import('src/views/Reports/HeadWiseDemandCollectionReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/Demand',
        component: lazy(() => import('src/views/Reports/DemandReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/ConsolidateDemandCollection',
        component: lazy(() =>
          import('src/views/Reports/ConsolidatedDemandCollectionReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/DailyBankReport',
        component: lazy(() => import('src/views/Reports/DailyBankReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/CancellationReport',
        component: lazy(() => import('src/views/Reports/CancellationReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/GeneralDailyBankReport',
        component: lazy(() =>
          import('src/views/Reports/GeneralDailyBankReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/GeneralDayBook',
        component: lazy(() => import('src/views/Reports/GeneralDayBookReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/Refund',
        component: lazy(() => import('src/views/Reports/RefundReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/DepartmentConsolidateBalance',
        component: lazy(() =>
          import('src/views/Reports/DepartmentConsolidatedBalanceReport')
        )
      },
   
      {
        exact: true,
        path: '/app/admin/reports/ExcessFee',
        component: lazy(() => import('src/views/Reports/ExcessFeeReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/ConsolidateBalance',
        component: lazy(() =>
          import('src/views/Reports/ConsolidatedBalanceReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/Consolidate',
        component: lazy(() => import('src/views/Reports/ConsolidatedReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/StudentConsolidateBalance',
        component: lazy(() =>
          import('src/views/Reports/StudentConsolidatedBalanceReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/BalanceReport',
        component: lazy(() => import('src/views/Reports/BalanceReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/OnlinePaymentReport',
        component: lazy(() => import('src/views/Reports/OnlinePaymentReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/SettlementReport',
        component: lazy(() =>
          import('src/views/Reports/OnlineSettlementReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/HeadWiseReport',
        component: lazy(() => import('src/views/Reports/CustomHeadReport'))
      },

      {
        exact: true,
        path: '/app/admin/reports',
        component: lazy(() => import('src/views/Reports'))
      },
      {
        exact: true,
        path: '/app/admin/import_students',
        component: lazy(() => import('src/views/ImportCETStudents'))
      },
      {
        exact: true,
        path: '/app/admin/notifications',
        component: lazy(() => import('src/views/MessageLogs'))
      },
    ]
  },
  // {
  //   path: '/app',
  //   guard: AuthGuard,
  //   layout: AllApps,
  //   routes: [
  //     {
  //       exact: true,
  //       path: '/app/admin/applications/detail',

  //       component: lazy(() => import('src/views/Applications/AppDetails'))
  //     },
  //   ]
  // },

  {
    path: '*',
    // layout: MainLayout,
    routes: [
      {
        exact: true,
        guard: GuestGuard,
        path: '/',
        component: lazy(() => import('src/views/auth/LoginView/JWTLogin'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
